const _window = $(window);
const body = $('body');
import Cursor from './cursor';

export let cursorElement;
export let customCursor;


if (matchMedia('(any-pointer:fine)').matches)
{
	/**
	 * Initialize custom cursor
	 */
	cursorElement = document.querySelector('.cursor');
	customCursor = new Cursor(cursorElement);


	/**
	 * Show a bigger cursor when over a link
	 */
	[...document.querySelectorAll('a, .link-all-item, .module-accordion__toggle')].forEach(
		(item, pos) => {

			item.addEventListener('mouseenter', function () {
				customCursor.enterActiveElement();
			});

			item.addEventListener('mouseleave', function () {
				customCursor.leaveActiveElement();
			});
		}
	);


	[...document.querySelectorAll('.swiper-container:not(.disabled):not(.hero-azienda .swiper-container)')].forEach(
		(item, pos) => {

			item.addEventListener('mouseenter', function () {
				cursorElement.classList.add('cursor--swipe');
				customCursor.enterSlideshow();
			});

			item.addEventListener('mouseleave', function () {
				cursorElement.classList.remove('cursor--swipe');
				customCursor.leaveSlideshow();
			});
		}
	);
}