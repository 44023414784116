// assegna un link a tutto l'elemento padre
$.fn.bindLinkToItem = function() {

	return this.each(function() {
		let anchor;

		// ritorna il link dell'elemento link designato oppure dell'ultima ancora di ogni elemento
		if ($(this).find('.item-link').length > 0)
		{
			anchor = $(this).find('.item-link');
		}
		else
		{
			anchor = $(this).find('a').filter(function( index ) {
				let link = $(this).attr('href');

				if (link !== '#' && link !== 'javascript:;' && link !== '')
				{
					return true;
				}
				return false;
			}).last();
		}

		if (anchor.length > 0)
		{
			if (anchor.hasClass('venobox'))
			{
				$(this).on('click', function (e) {
					anchor.trigger('click');
				}).addClass('link-all-item');
			}
			else
			{
				let link = anchor.attr('href');

				$(this).on('click mousedown', function (e) {
					// if the e.target is a different element than this and is an anchor, you've clicked on a descendant link,
					// so exit the function and follow the clicked link
					if ($(e.target).is('a') && e.target !== this)
						return;

					e.stopPropagation();
					e.preventDefault();

					if (e.originalEvent.ctrlKey || e.which === 2 || anchor.attr('target') === '_blank')
					{
						window.open(link, "_blank");
					}
					else
					{
						location.href = link;
					}
				}).addClass('link-all-item');
			}
		}
	});
};

$('.link-all-item').bindLinkToItem();