let slideshowSwiper = new Swiper('.slideshow .swiper-container', {
	slidesPerView: 'auto',
	//spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});


let numbersSwiper = new Swiper('.slideshow-numbers', {
	slidesPerView: 'auto',
	pagination:    {
		el: '.swiper-pagination'
	},
	watchOverflow: true, // disable swiper if there's only one slide
	on: {
		lock: function (swiper) {
			swiper.el.classList.add('disabled');
		}
	}
});


let moduleSlideshowSwiper = new Swiper('.module-slideshow .swiper-container', {
	slidesPerView: 'auto',
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});


let sezioneSettoriSwiper = new Swiper('.sezione-settori__slideshow .swiper-container', {
	slidesPerView: 'auto',
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		992: {
			slidesOffsetBefore: function () {
				return $(this.el).width() / 24;
			},
		}
	}
});



let heroAziendaSwiper = new Swiper('.hero-azienda .swiper-container', {
	noSwiping:      true,
	noSwipingClass: 'swiper-slide',
	slidesPerView:  'auto',
	loop:           true,
	loopedSlides:   1,
	autoplay:       {
		delay: 3000,
	},
});